<template>
  <div id="div" class="fill-vh background-gradient">
    <NavBar />
    <router-view />
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";

export default {
  name: "App",
  components: {
    NavBar,
  },
  mounted() {
    document.title = "Snapguessr";
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  caret-color: transparent;
  background-image: linear-gradient(to bottom, rgba(242, 238, 237, 1) 90%, rgba(242, 238, 237, 0) 100%);
}

.fill-height {
  height: 100%;
}

.fill-width {
  width: 100%;
}

.fill-vw {
  width: 100vw;
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 10;
}

@media (min-width: 768px) {
  /* Navbar width needs to be subtracted from the rest of the space */
  .fill-vh {
    height: calc(100vh - 46px);
  }
}

@media (max-width: 768px) {
  .fill-vh {
    height: calc(100vh - 56px);
  }
}

/* Temp color classes for quick debugging */
.red {
  background-color: red;
}

.blue {
  background-color: blue;
}

.green {
  background-color: green;
}
</style>
