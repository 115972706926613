<template>
  <div class="game-container" v-if="!gameCompleted">
    <div class="top">
      <div class="fill-height fill-width" v-if="imageUrl">
        <ZoomistComp v-if="viewportWidth > 768" :imageUrl="imageUrl">
        </ZoomistComp>
        <ImageComp v-else :imageUrl="imageUrl"></ImageComp>
      </div>
      <div v-else class="spinner-container">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div class="bottom">
      <SliderComp style="margin-bottom: 20px;"></SliderComp>
      <div v-if="userIsGuessing">
        <p class="h3 inline year">
          <span id="selectedYear"></span>
        </p>
        <button type="button" @click="guess()" class="inline btn btn-success">
          Confirm
        </button>
      </div>
    </div>
    <transition name="slide-up">
      <div v-if="!userIsGuessing" class="popup-panel bg-primary">
        <div class="scrollable-text">
            <h2 v-if="stars >= 0">{{ getEndingMsg() }}</h2>
            <p class="h4 "> {{ description }}</p>
        </div>
        <div style="text-align: center;">
          <StarComponent class="inline stars" :stars=stars></StarComponent>
          <button type="button" @click="next()" class="btn btn-success inline">
            Next
          </button>
        </div>
      </div>
    </transition>
  </div>
  <div v-if="gameCompleted">
    <h2> Your Score: <StarComponent :stars=totalScore></StarComponent>
    </h2>
    <button type="button" @click="nextRound()" class="btn btn-success btn-lg">
      Next Round
    </button>
  </div>
</template>

<script>
import { getRandomImages } from '../utils/firebase/storage/ImageFireStoreManager.js';
import StarComponent from "../components/Star.vue";
import SliderComp from "../components/SliderComp.vue";
import ZoomistComp from "../components/ZoomistComp.vue";
import ImageComp from "../components/ImageComp.vue";
import confetti from 'canvas-confetti';

export default {
  name: "PhotoGuessrView",
  components: {
    StarComponent,
    ZoomistComp,
    SliderComp,
    ImageComp
  },
  props: {
    photoView: Object
  },
  data() {
    return {
      userIsGuessing: true,
      currentItemIndex: 0,
      correctGuess: 1982,
      gameCompleted: false,
      selectedItems: [],
      selectedYear: "ad",
      currentItem: {},
      imageUrl: "",
      description: "",
      docs: {},
      stars: -1,
      totalScore: -1,
      height: 0,
      viewportWidth: window.innerWidth,
    }
  },
  async mounted() {
    this.docs = await getRandomImages(5);
    this.currentItem = this.docs[0];
    this.correctGuess = this.currentItem.year;
    this.imageUrl = this.currentItem.url;
    this.description = this.currentItem.description;
    window.addEventListener('resize', this.updateViewportWidth);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateViewportWidth);

  },
  methods: {
    updateViewportWidth() {
      this.viewportWidth = window.innerWidth;
    },
    guess() {
      var year = document.getElementById('selectedYear').innerText;
      this.selectedYear = year;
      var difference = Math.abs(this.correctGuess - this.selectedYear);
      if (difference == 0) {
        this.stars = 5;
        this.launchFireworks();
      } else if (difference < 10) {
        this.stars = 4;
      } else if (difference < 20) {
        this.stars = 3;
      } else if (difference < 30) {
        this.stars = 2;
      } else {
        this.stars = 1;
      }
      this.currentItem.star = this.stars;
      this.selectedItems.push(this.currentItem);
      this.userIsGuessing = false;
      var slider = document.getElementById('slider');
      slider.noUiSlider.disable();
    },
    launchFireworks() {
      confetti({
        particleCount: 400,
        spread: 70,
        origin: { y: 0.6 },
      });
    },
    next() {
      this.userIsGuessing = true;
      var slider = document.getElementById('slider');
      slider.noUiSlider.enable();
      setTimeout(() => {
        slider.noUiSlider.reset();
      }, "100");
      var zoomSlider = document.getElementsByClassName('zoomist-slider');
      if (zoomSlider[0] != undefined) {
        window.zoomist.reset();
      }
      this.stars = -1;
      this.currentItemIndex = this.currentItemIndex + 1;
      if (this.currentItemIndex == 5) {
        console.log("game end");
        let sum = 0;
        for (let i = 0; i < this.selectedItems.length; i++) {
          sum += this.selectedItems[i].star;
        }
        this.totalScore = Math.round(sum / 5);
        this.gameCompleted = true;
      } else {
        this.currentItem = this.docs[this.currentItemIndex];
        this.correctGuess = this.currentItem.year;
        this.imageUrl = this.currentItem.url;
        this.description = this.currentItem.description;
      }
    },
    nextRound() {
      this.$router.go(0);
    },
    getEndingMsg() {
      if (this.stars == 5) {
        return `Spot On! The year is ${this.correctGuess}`
      } else if (this.stars == 4) {
        return `${this.selectedYear} is very close! The correct answer is ${this.correctGuess}`
      } else if (this.stars < 4 && this.stars > 0) {
        return `${this.selectedYear} is far off! The correct answer is ${this.correctGuess}`
      }
    }
  },
};
</script>

<style scoped>
.game-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.top {
  height: 70%;
  width: 100vw;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.bottom {
  height: 40%;
  width: 100vw;
  min-height: 250px;
  box-shadow: 0 -10px 15px rgba(128, 128, 128, 0.15);
  overflow: hidden;
}

@media (min-width: 768px) {
  .top {
    height: 80%;
  }

  .bottom {
    height: 20%;
  }
}

@media (max-width: 768px) {
  .top {
    height: 60%;
  }

  .bottom {
    height: 40%;
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.inline {
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}

.year {
  margin: 0px 15px;
}

.stars {
  margin-right: 15px;
}

.btn {
  min-width: 80px;
}

.popup-panel {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #808d90 !important;
  border-top: 1px solid white;
  color: white;
  padding: 20px;
  text-align: center;
  z-index: 10;
  opacity: 0.9;
}

.scrollable-text {
  display: block;
  max-height: 60vh;
  min-height: 50px; /* Ensures some height for scrolling */
  overflow-y: auto;
}

/* Slide up animation */
.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.5s ease;
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(100%);
}
</style>
